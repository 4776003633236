<template>
  <div class="school-data-student">
    <div class="mb-6">
      <a
        class="text-decoration-none text-subtitle-2 font-weight-bold mb-6"
        @click.prevent="returnBack"
      >
        <v-icon
          color="primary"
          dense
          class="mr-2"
        >
          {{ icons.mdiArrowLeft }}
        </v-icon>
        Kembali
      </a>
    </div>
    <MainCard
      v-if="!isLoadingTable"
    >
      <template v-slot:body>
        <DataTablePagination
          title="Data Siswa"
          subtitle="Halaman untuk melihat total seluruh siswa"
          :total-items="totalItems"
          :headers="headers"
          :items="students"
          :search="search"
          :total-pages.sync="totalPages"
          :is-loading="isLoadingData"
          :no-add-button="true"
          @page="page"
          @change-page="paginationHandler"
          @live-search="searchHandler"
        >
        </DataTablePagination>
      </template>
    </MainCard>
    <SkeletonLoaderTable v-else></SkeletonLoaderTable>
  </div>
</template>

<script>
import { mdiArrowLeft } from '@mdi/js'
import DataTablePagination from '../../components/DataTablePagination.vue'
import MainCard from '../../components/MainCard.vue'
import SkeletonLoaderTable from '../../components/SkeletonLoaderTable.vue'

export default {
  name: 'SchoolDataStudent',
  components: {
    DataTablePagination,
    SkeletonLoaderTable,
    MainCard,
  },
  data() {
    return {
      students: [],
      student: {},
      icons: {
        mdiArrowLeft,
      },
      search: '',
      page: 1,
      totalItems: 0,
      totalPages: 0,
      isLoadingData: false,
      isLoadingTable: true,
      service: 'student',
      headers: [
        { text: '#', value: 'index' },
        { text: 'NIS', value: 'nis' },
        { text: 'Nama', value: 'name' },
        { text: 'Username', value: 'username' },
        { text: 'Jenis Kelamin', value: 'gender' },
        { text: 'Alamat', value: 'place.address' },
      ],
    }
  },
  watch: {
    page: {
      handler() {
        this.getStudent()
      },
    },
    search: {
      handler() {
        this.getStudent()
      },
    },
  },
  async mounted() {
    await this.getSchoolUuid()
    this.getStudent()
  },
  methods: {
    async getStudent(params = {}) {
      await this.$services.ApiServices.list(this.service, {
        ...params,
        school_uuid: this.school_uuid,
        page: this.page,
        search: this.search,
      }).then(({ data }) => {
        this.students = data.data.map((student, index) => ({
          index: index + 1,
          ...student,
        }))
        this.isLoadingTable = false

        this.totalItems = data.meta.total
        this.totalPages = data.meta.last_page
      })
    },
    getSchoolUuid() {
      this.school_uuid = this.$route.params.uuid
    },
    searchHandler(data) {
      this.search = data
    },
    async paginationHandler(pageNumber) {
      this.page = pageNumber
    },
    returnBack() {
      this.$router.back()
    },
  },
}
</script>

<style>
</style>
